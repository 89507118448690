<template>
  <div
    class="page songlist-page"
    v-log="{
      event_type: 'show',
      event_name: 30173,
      event_data: {
        str1: '歌手详情页',
        str2: '歌手详情页',
        str3: '进入歌手详情页',
        str4: 'show',
      },
    }"
  >
    <HeadBar
      class="songlist-headbar"
      :title="listSheet.name"
      :isShowSinging="false"
      :isShowSetting="false"
      :isShowRight="!isSpecialSongList"
      :isShowSearchImg="true"
    />
    <div
      v-if="browserType !== 'landscape' && isSpecialSongList"
      class="songlist-headbar-title"
    >
      特别定制 会员专享歌单
    </div>

    <div class="list" :class="{ 'list-special': !isSpecialSongList }">
      <div class="list-left">
        <div
          v-if="vip_cnt && !isVip"
          class="list-header"
          @click="handleOpenVipModal"
          v-log="{
            event_type: 'show',
            event_name: 30165,
            event_data: {
              str1: '歌手详情页',
              str2: '歌曲列表',
              str3: '会员文字链展示',
              str4: 'show',
              str5: isLogin ? '已登录' : '未登录',
              str7: userType,
            },
          }"
        >
          <div class="left">开通会员畅享全部</div>
          <div class="right">VIP仅<span>0.2</span>元/天</div>
        </div>
        <LoadMore
          class="song-list"
          ref="loadMoreRef"
          v-if="dataList.length"
          @load-more="handleLoadMore"
          :safeAreaHeight="
            browserType !== 'landscape' && !isSpecialSongList ? '5vw' : '15vw'
          "
        >
          <SongItem
            v-for="(songItem, index) in dataList"
            className="no-padding"
            :key="index"
            :songItem="songItem"
            :keyword="keyword"
            :log-from="{
              song_list_source: listSheet.singerid ? 6 : 3,
              song_list: listSheet.name,
              song_list_id: listSheet.name,
              fr1: isSpecialSongList ? 1874 : 1802,
              fr2: '歌星详情页会员歌曲',
            }"
            :ponitActionLog="ponitActionLog"
          />
        </LoadMore>
        <div v-else-if="showEmpty">
          <div class="list-left-empty">
            <img
              src="https://qncweb.ktvsky.com/20231211/vadd/3bc6fd2689cda7c52578140c345c6272.png"
              alt=""
            />
            <p>抱歉，暂无“{{ listSheet.name }} {{ keyword }}”结果</p>
          </div>
        </div>
      </div>
      <div class="list-cover">
        <div class="list-cover-img">
          <img :src="listSheet.image" />
        </div>
        <p>{{ listSheet.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
// import LbBanner from '@/components/lb-banner/index.vue'
import SongItem from '@/components/song-item/index.vue'
import { computed, onBeforeMount, ref, watch } from 'vue'
import { onBeforeRouteLeave, useRoute } from 'vue-router'
import { useStore } from 'vuex'
// import PureSearch from '../search/components/search-bar/index.vue'
import { useShareBrowserSize } from '@/composables/sharedComposable'
import { sendLog } from '@/directives/v-log/log'
import { getHotSongList } from '@/service/hotSongList'
import { getPlaylistDetail } from '@/service/playlist'
import { searchSinger, searchSingerSong } from '@/service/search'
import useVip from '@/composables/useVip'

export default {
  name: 'SongList',
  components: {
    SongItem,
    // LbBanner,
    // PureSearch,
  },
  activated() {
    const store = useStore()
    const { isUsed, position } = store.state.pageCacheData.playlist
    if (!isUsed) {
      this.handleInitData()
    } else {
      // 放在这里统一处理 - 防止记忆activated钩子与onBeforeMount刷新时加载重复
      this.fetchData()
      if (this.$refs.loadMoreRef) {
        this.$refs.loadMoreRef.root.scrollTop = position
      }
      this.handleUpdateCachePosition(false, 0)
    }
  },
  setup() {
    const route = useRoute()
    const store = useStore()
    const { browserType } = useShareBrowserSize()

    const { showVipQrcode } = useVip()
    const unionid = computed(() => store.state.userInfo.unionid)
    const isLogin = computed(() => !!unionid.value)
    const userType = computed(() => store.state.userInfo.userType)
    const isVip = computed(() => !!store.state.vipInfo.end_time)

    let loadMoreRef = ref(null)
    const dataList = ref([])
    let singerbg = ref('')
    let singerhead = ref('')
    let vip_cnt = ref(0)
    const imgFallback = {
      loading:
        'https://qncweb.ktvsky.com/20231226/vadd/9a350e743cc80ede01c2d3e4b7807eae.png',
      error:
        'https://qncweb.ktvsky.com/20231226/vadd/9a350e743cc80ede01c2d3e4b7807eae.png',
    }

    let listSheet = ref({
      name: '',
      image: '',
      singerid: '',
    })
    let p = 1
    let version = ref({
      current: '',
      latest: '',
    })
    let songType = ref('')
    let isRequest = false
    let isSearchRequest = false
    let isGedan = ref(false)
    let keyword = ref('')
    const showEmpty = ref(false)

    const isSpecialSongList = computed(
      () => listSheet.value.name === '“特”别定制，会员专享歌单'
    )

    const ponitActionLog = computed(() =>
      listSheet.value.singerid
        ? {
            event_type: '10000~50000',
            event_name: 10045,
            event_data: {
              str1: '歌星',
              str2: '歌手区',
              str3: '进入任意歌手',
              str4: 'click',
            },
          }
        : {}
    )

    const requestBussinessData = async () => {
      let responseData = []
      const { singerid, name } = listSheet.value
      if (singerid) {
        const { data } = await searchSinger(singerid, p)
        responseData = {
          data: data.song,
        }
        singerbg.value = ''
        singerhead.value = data.singerhead ? data.singerhead : ''
        vip_cnt.value = data.vip_cnt ? data.vip_cnt : 0
      } else {
        responseData = await getPlaylistDetail({
          p,
          type: name,
          version: version.value.latest,
        })
      }
      return responseData
    }

    const requestHotSongData = async () => {
      const res = await getHotSongList({
        p,
        unionid: unionid.value,
        version: version.value.latest,
      })
      if (res.data.length) {
        if (p === 1 && res.version) {
          version.value = res.version
        }
        dataList.value = dataList.value
          .concat(res.data)
          .filter((v, i) => i < 100)
        p++
      }
    }

    const fetchData = async () => {
      if (isRequest) {
        return
      }
      isRequest = true

      if (listSheet.value.name === '热门歌曲，总有一首你会唱') {
        if (!dataList.value.length) await requestHotSongData()
        isRequest = false
        return
      }

      if (listSheet.value.name === '“特”别定制，会员专享歌单') {
        singerhead.value = route.query.image
        isGedan.value = true
      }

      const { data } = await requestBussinessData()
      if (data.length) {
        p === 1 && data.version && (version.value = data.version)
        dataList.value = [...dataList.value, ...data]
        p++
      }
      isRequest = false
      showEmpty.value = !dataList.value.length
    }

    const handleLoadMore = () => {
      if (keyword.value) {
        getSearchResult()
      } else {
        fetchData()
      }
    }

    const handleInitData = () => {
      dataList.value = []
      listSheet.value = route.query
      p = 1
      version.value = {
        current: '',
        latest: '',
      }
      songType = ''
      isRequest = false
      fetchData()
    }

    const handleUpdateCachePosition = (u, v) => {
      store.commit('UPDATE_PAGE_CACHEDATA', {
        data: {
          isUsed: u,
          position: v,
        },
        type: 'playlist',
      })
    }

    const handleOpenVipModal = () => {
      sendLog({
        event_type: 'show',
        event_name: 30166,
        event_data: {
          str1: '歌手详情页',
          str2: '歌曲列表',
          str3: '会员文字链弹窗展示',
          str4: 'show',
          str5: isLogin.value ? '已登录' : '未登录',
          str7: userType.value,
        },
      })
      showVipQrcode({
        log: '歌星详情页会员文字链',
        isLogin: isLogin.value,
        fr: 1866,
      })
    }

    const handleSearch = (k) => {
      sendLog({
        event_type: 'show',
        event_name: 30283,
        event_data: {
          str1: '歌手详情页',
          str2: '搜索',
          str3: '点击',
          str4: 'click',
        },
      })
      keyword.value = k
    }

    const searchHandler = {
      song: async () => {
        const bussinessResponseData = await searchSingerSong(
          listSheet.value.singerid,
          keyword.value,
          p
        )
        if (bussinessResponseData.song.length) {
          const songvipsort = bussinessResponseData.song
          dataList.value = dataList.value.concat(songvipsort)
          p++
        }
        isSearchRequest = false
      },
    }

    const getSearchResult = async () => {
      if (isSearchRequest) {
        return
      }
      isSearchRequest = true
      searchHandler['song'].call()
    }

    watch(keyword, (k) => {
      if (k) {
        dataList.value = []
        p = 1
        getSearchResult()
      } else {
        handleInitData()
      }
    })

    onBeforeMount(() => {
      listSheet.value = route.query
      if (route.name === 'songList') {
        fetchData()
      }
    })

    onBeforeRouteLeave((to, from, next) => {
      if (to.name === 'search' && from.name === 'playList') {
        const position = loadMoreRef.value
          ? loadMoreRef.value.root.scrollTop
          : 0
        handleUpdateCachePosition(true, position)
      }
      next()
    })

    return {
      imgFallback,
      loadMoreRef,
      singerbg,
      singerhead,
      dataList,
      listSheet,
      ponitActionLog,
      songType,
      keyword,
      fetchData,
      handleInitData,
      handleUpdateCachePosition,
      vip_cnt,
      handleOpenVipModal,
      isLogin,
      userType,
      isVip,
      isGedan,
      browserType,
      handleSearch,
      isSpecialSongList,
      handleLoadMore,
      showEmpty,
    }
  },
}
</script>

<style lang="stylus" scoped>
.page
  .search-bar
    background none
  .singer-bg
    position absolute
    top 110px
    right 0
    width 807px
    height auto
    z-index 0
  ::v-deep .v-progressive-image
    & > div
      padding-bottom 0!important
    .v-progressive-image-placeholder
      width 807px!important
      height auto
  .empty-wrapper
    margin 0 auto
  .empty
    color: rgba(255, 255, 255, 0.40);
    font-size 28px
    background url('https://qncweb.ktvsky.com/20231211/vadd/3bc6fd2689cda7c52578140c345c6272.png') no-repeat top center
    background-size 80px auto
    padding-top 100px
    text-align center
    margin 245px auto 0
    @media screen and (max-width 1200px) and (min-height 1200px)
      margin-top 409px
.infinite-loading
  display flex
  justify-content center
.songlist-page
  padding 162px 0px 0px 0px
  .list
    width 100vw
    height calc(100vh - 150px - 110px)
    position relative 
    display flex
    justify-content space-between
    padding 0 0 0px 70px
    @media screen and (max-width 1200px) and (min-height 1200px)
      display flex
      flex-direction column
    &-cover
      margin-right 230px
      margin-top 60px
      color rgba(29, 29, 31, 0.8)
      font-size 52px
      text-align center
      &-img
        border-radius 50%
        width 400px
        height 400px
        overflow hidden
        margin 0 auto 140px
        background url('https://qncweb.ktvsky.com/20241129/other/5293f38039c91d3d049687533c2155ad.png') no-repeat
        background-size 100%
        img
          width 100%
          height 100%
    &-header
      width 950px !important
      display flex
      position sticky
      top 0
      @media screen and (max-width 1200px) and (min-height 1200px)
        width 100% !important
        margin-top 20px
        margin-left 0px
      height 80px
      background: linear-gradient(90deg, #E6C0A3 0%, #FEE4D3 100%, #FEE4D3 100%);
      border-radius: 8px;
      display flex
      justify-content space-between
      align-items center
      padding 0 20px 0 24px
      margin-bottom 12px !important
      .left
        padding-left 74px
        background url('https://qncweb.ktvsky.com/20240329/other/e8e4b97b61385a1f09f6b7ce2005d53a.png') left center no-repeat
        background-size 62px auto
        font-weight 700
        font-size 32px
      .right
        color #412F20
        font-size 30px
        background none
        padding-right 30px
        font-weight 700
        span
          color #DC302B
          padding 0 3px
          font-size 44px
          font-style italic
          font-weight 700
    &-left
      width 980px
      margin 0 !important
      padding 0 0 80px !important
      @media screen and (max-width 1200px) and (min-height 1200px)
        width 1080px
        height 100%
      &-bg
        display none
      &-title
        font-size 36px
        color rgba(219, 174, 106, 1)
        font-weight 500
        text-align left
        width 100%
        margin-bottom 20px
        line-height 1
      .song-list
        width 100% !important
        padding 0 !important
        height 100% !important
        ::v-deep .song
          height 141px !important
        @media screen and (max-width 1200px) and (min-height 1200px)
          padding 0 0 30px 0 !important
        &::-webkit-scrollbar
          display none
      .list-left-empty
        display flex
        flex-direction column
        justify-content center
        align-items center
        margin-top 24vh
        font-size 28px
        color rgba(255, 255, 255, 0.40)
        text-align center
        img
          width 80px
          height 80px
          margin-bottom 40px
        p
          height 32px
          line-height 32px
          margin-left 0
        @media screen and (max-width 1200px) and (min-height 1200px)
          margin-top 20vh
    @media screen and (max-width 1200px) and (min-height 1200px)
      flex-direction column
      justify-content center
      &-left
        width 100%
        display flex
        flex-direction column
        align-items center
        padding-left 0
        img
          width 200px
          height 200px
          margin-top 4px
        p
          margin-left 50px
          min-width 300px
        .song-list
          // padding 0 0 200px 0!important
        &-bg
          display flex
          flex-direction column
          justify-content center
          align-items center
          margin-top 20px
          img
            width 240px
            height 240px
            border-radius 50%
          p
            text-align center
            font-size: 32px;
            color: rgba(204, 230, 238, 0.80);
            margin 48px 0 18px
      &-right
        display none
        width 100%
        padding-left 0
        margin-top 8px
        .song-list
          height 76.6vh !important
    .hint
      text-align center
      color #555555
  .list-special
    padding-bottom 0px
  .songlist-headbar
    background transparent
  .songlist-headbar-title
    font-size 36px
    color rgba(219, 174, 106, 1)
    font-weight 500
    position absolute
    top 775px
    left 0px
    text-align center
    width 100%
  .songlist-bar-pure
    background transparent
    position fixed
    top 164px
    left 1014px
    width 826px
    padding 0
    @media screen and (max-width 1200px) and (min-height 1200px)
      top 775px
      left 0
      right 0
      bottom 0
      width 100%
      padding 0 5vw

@media screen and (max-width 1200px) and (min-height 1200px)
  .songlist-page
    height 100vh
    overflow hidden
    .list
      flex-direction column-reverse
      align-items center
      padding 0 64px
      height 100% !important
      &-left
        flex 1
        height auto
        overflow-y scroll
        padding-bottom 0px !important
        .song-list
          padding-bottom 200px !important
      &-cover
        margin 64px 0 60px
        &-img
          margin-bottom 36px
</style>
